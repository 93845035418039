<template>
  <div class="flex-container">
    <div class="item">The link you are looking for doesn’t exists.</div>
    <div class="item">
      To sign-up for Social Wonder checkout our website:
      <a
        class="contact-support-block__support-email"
        href="https://social-wonder.com/"
      >
        www.social-wonder.com</a
      >
    </div>
    <div class="item">
      Need help? Reach out at:
      <a
        class="contact-support-block__support-email"
        :href="`mailto:support@social-wonder.com?subject=Help%20needed&body=${mailToHref}`"
        >support@social-wonder.com</a
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PageNotFoundSaas",
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),

    mailToHref() {
      const mailBody = `Name: ${
        this.getCurrentSmbCustomer.personal_name || ""
      }\nEmail: ${this.getCurrentSmbCustomer.email || ""}\nPhone: ${
        this.getCurrentSmbCustomer.primary_phone || ""
      }\n`;
      return encodeURI(mailBody);
    },
  },
  methods: {
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
  },
  async created() {
    if (this.$route.params.id && !this.getCurrentSmbCustomer.id) {
      await this.fetchSmbClientInfo(this.$route.params.id);
    }
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .item {
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
